import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from '../routes';

interface Route {
  path: string;
  element: JSX.Element;
  children?: Route[];
}

const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const sampleNavigate = (path: string, option?: { replace?: boolean }) => {
    const currentPage = routes.find(route => route.path === path);
    if (currentPage) {
      navigate(path, option);
    } else {
      console.error(`Route not found for path: ${path}`);
    }
  };

  return useMemo(
    () => ({
      navigate: sampleNavigate,
      location,
    }),
    [location]
  );
};

export default useRouter;
