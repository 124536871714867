import loadable from "@loadable/component"

export const MainPage = loadable(
  () => import('./MainPage'),
)

export const ChargingPage = loadable(
  () => import('./ChargingPage'),
)

export const FinishedPage = loadable(
  () => import('./FinisedPage'),
)

export const NotFoundPage = loadable(
  () => import('./NotFoundPage'),
)

export const StatusPage = loadable(
  () => import('./StatusPage'),
)

export const RedirectPage = loadable(
  () => import('./RedirectPage'),
)

export const PagePaymentIframe = loadable(
  () => import('./PagePaymentIframe')
)

export const PaymentPage = loadable(
  () => import('./PaymentPage')
)