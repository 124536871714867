import * as React from "react";
import{ Navigate, RouteProps } from "react-router-dom"
import * as pages from "../pages";
import { RedirectPage } from '../pages';

interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    element: <Navigate to="/main" replace />,
  },
  {
    path: '/main',
    element: <pages.MainPage />,
  },
  {
    path: '/charging',
    element: <pages.ChargingPage />,
  },
  {
    path: '/finished',
    element: <pages.FinishedPage />,
  },
  {
    path: '/*',
    element: <pages.NotFoundPage />,
  },
  {
    path: '/status',
    element: <pages.StatusPage />,
  },
  {
    path: '/session-start/*',
    element: <pages.RedirectPage />,
  },
  {
    path: '/payment',
    element: <pages.PagePaymentIframe/>,
  },
  {
    path: '/midtrans',
    element: <pages.PaymentPage/>,
  },
]

export default routes;